<template>
    <div class="container-fluid">
        <list-desktop
            v-if="!$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:search_q="searchBack"
            v-on:load_sites="loadSites"
            v-on:delete_site="deleteSite"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:show_details="details"
        >
        </list-desktop>

        <list-mobile
            v-if="$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:search_q="searchBack"
            v-on:load_sites="loadSites"
            v-on:delete_site="deleteSite"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:show_details="details"
        >
        </list-mobile>
    <mdb-modal
        centered
        size="md" 
        direction="top"
        position="top"
        :show="modalSite.show" 
        @close="modalSite.show = false"  
    >
        <form @submit.prevent='modalSite.edit ? update() : save()'>
        <mdb-modal-header>
                <mdb-modal-title>
                    {{ modalSite.title }}
                </mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
                <fieldset>
                    <mdb-row>
                        <mdb-col sm="12" md="12">
                            <mdb-input 
                                :disabled="form.default_create === 1"
                                :validation="$store.state.site.errors.name ? true :false" :invalidFeedback="$store.state.site.errors.name"
                                v-model="form.name"
                                @change="nameFormatter"
                              size="lg" outline label="Site"></mdb-input>
                        </mdb-col>
                            
                    </mdb-row>
                </fieldset>
                <fieldset v-if="form.is_station">
                    <mdb-row>
                        <mdb-col sm="6" md="6">
                            <mdb-input
                                :validation="$store.state.site.errors.nbr_tank ? true :false" :invalidFeedback="$store.state.site.errors.nbr_tank"
                                v-model="form.nbr_tank"
                                type="number"
                              size="md" outline label="Nombre de Cuve"></mdb-input>
                        </mdb-col>
                        <mdb-col sm="6" md="6">
                            <mdb-input
                                :validation="$store.state.site.errors.nbr_pistol ? true :false" :invalidFeedback="$store.state.site.errors.nbr_pistol"
                                v-model="form.nbr_pistol"
                                type="number"
                              size="md" outline label="Nombre de pistolet"></mdb-input>
                        </mdb-col>
                    </mdb-row>
                </fieldset>
                <fieldset class="">
                    <legend>Avancé</legend>
                    <mdb-row>   
                        <mdb-col sm="12" md="6" class="" >
                            <label for="">Est une station pétrolière</label>
                            <mdb-switch v-model="form.is_station" offLabel="Non" onLabel="Oui"  />
                        </mdb-col>
                        <mdb-col sm="12" md="6" class="" >
                            <label for="">Etat du site?</label>
                            <mdb-switch v-model="form.is_active" offLabel="Désactivé" onLabel="Activé"  />
                        </mdb-col>
                    </mdb-row>
                </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer>
            <mdb-btn size="md" color="primary" type="submit" :disabled="modalSite.btn">
                <span v-if="!modalSite.btn">Sauvegarder</span>
                <span v-if="modalSite.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="modalSite.btn" class="pl-2">Loading...</span>
            </mdb-btn>
            <mdb-btn size="md" outline="primary" @click="modalSite.show = false">Fermer</mdb-btn>

        </mdb-modal-footer>
        </form>
    </mdb-modal>

    <mdb-modal 
        size="lg" 
        direction="top"
        position="top"
        :show="modalSitePan.show" 
        @close="modalSitePan.show = false"
        >
        <mdb-modal-header>
            <mdb-modal-title>
                {{modalSitePan.content.name}}
            </mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body style="min-height:300px" >
            <mdb-row>
                <mdb-col sm="12" md="12"  lg="5" style="" class="mb-5" >
                    <div>
                       <mdb-tbl>
                        <mdb-tbl-body>
                            <tr>
                                <th class="ml-0 pl-0">Abonnement</th>
                                <td> 
                                    <span
                                    v-if="modalSitePan.content.is_subscribed === 1"
                                    class="badge"
                                    :class="checkColorSub($moment(modalSitePan.content.expire_at).fromNow())"
                                    >Expire {{ $moment(modalSitePan.content.expire_at).fromNow() }}</span
                                    >
                                    <span v-if="modalSitePan.content.is_subscribed === 0" class="badge badge-info"
                                    >aucun abonnement</span
                                    ></td>
                            </tr>
                            <tr>
                                <th class="pl-0" colspan="">Station</th>
                                <th style="">
                                    <span v-if="modalSitePan.content.is_station">Oui</span>
                                    <span v-if="!modalSitePan.content.is_station">Non</span>
                                </th>
                            </tr>
                            <tr v-if="modalSitePan.content.is_station">
                                <th class="pl-0" colspan="">Nombre de Cuve</th>
                                <th style="">
                                   {{modalSitePan.content.nbr_tank}}
                                </th>
                            </tr>
                            <tr v-if="modalSitePan.content.is_station">
                                <th class="pl-0" colspan="">Nombre de Pistolet</th>
                                <th style="">
                                   {{modalSitePan.content.nbr_pistol}}
                                </th>
                            </tr>
                        </mdb-tbl-body>
                       </mdb-tbl>
                    </div>
                </mdb-col>
                <mdb-col sm="12"  md="12" lg="7" >
                    <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h5 style="font-weight: 500; text-transform:uppercase">Utilisateurs</h5>
                    </div>
                    </div>
                    <small>
                        <span
                        class="badge rounded-pill bg-success"
                        style="height: 10px; width: 10px; display: inline-block"
                        ></span> activé
                        <span
                        class="badge rounded-pill bg-danger ml-3"
                        style="height: 10px; width: 10px; display: inline-block"
                        ></span> desactivé
                        <span
                        class="badge rounded-pill bg-secondary ml-3"
                        style="height: 10px; width: 10px; display: inline-block"
                        ></span> archivé
                    </small>
                    <mdb-tbl responsiveSm class="mt-3">
                        <mdb-tbl-head >
                            <tr class="">
                                <th class="py-2">Nom & Prenoms</th>
                            </tr>
                        </mdb-tbl-head>
                    </mdb-tbl>

                    <div class="mt-n2" style="height: 400px; max-height:400px; overflow-x:hidden;overflow-y:auto">
                        <mdb-tbl responsiveSm>
                            <mdb-tbl-body >
                                <tr class="" v-for="u in modalSitePan.content.users" :key="u.id">
                                    <td style="text-transform:capitalize" class="py-2" > 
                                        <span
                                        v-if="u.is_active === 1 & u.is_archived === 0"
                                        class="badge rounded-pill bg-success"
                                        style="height: 10px; width: 10px; display: inline-block"
                                        ></span>
                                        <span
                                        v-else-if="u.is_active === 0 & u.is_archived === 0"
                                        class="badge rounded-pill bg-danger"
                                        style="height: 10px; width: 10px; display: inline-block"
                                        ></span>
                                        <span
                                        v-else
                                        class="badge rounded-pill bg-secondary"
                                        style="height: 10px; width: 10px; display: inline-block"
                                        ></span>
                                    {{u.lastname}} {{u.firstname}} <small>{{u.email}}</small></td>
                                </tr>
                            </mdb-tbl-body>
                        </mdb-tbl>
                    </div>
                </mdb-col>
            </mdb-row>
        </mdb-modal-body>
    </mdb-modal>
    </div>
</template>

<script>
import ListDesktop from '@/tba/views/sites/modules/ListDesktop'
import ListMobile from '@/tba/views/sites/modules/ListMobile'
import {
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbBtn,
    mdbSwitch,
    mdbRow,mdbCol,
    mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalFooter,mdbModalHeader,
} from 'mdbvue'
export default {
    name:"sites",
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - SITES',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbTbl,
        mdbTblHead,
        mdbTblBody,
        mdbBtn,
        mdbSwitch,
        mdbRow,mdbCol,
        mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalFooter,mdbModalHeader,
        ListDesktop,ListMobile
    },

    data(){
        return {
            dataPagination: {
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "name",
                ],
            },

            loading:false,
            empty:false,
            data:{
                columns: [
                    {
                        label: "Site",
                        field: "name",
                        sort: true,
                    },
                    {
                        label: "Date Expiration",
                        field: "site",
                        sort: true,
                    },
                    {
                        label: " ",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[]
            },

            form:{
                id:"",
                name:"",
                nbr_tank:0,
                default_create:0,
                nbr_pistol:0,
                is_active:true,
                is_station:false
            },

            selection:{
                select_all:false,
                selected:[]
            },
            
            modalSiteDetails: {
                show: false,
                edit: false,
                title: "Détails du  site",
                content: {},
            },

            modalSitePan: {
                show: false,
                edit: false,
                title: "",
                content: {},
            },

            modalSite: {
                show: false,
                edit: false,
                title: "Ajouter un site",
                content: {},
                btn:false
            },
        }
    },

    methods:{
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalSite.title = "Modifier un site";
                this.modalSite.content = data;
                this.modalSite.edit = true;
                this.formRest(data);
            } else {
                this.modalSite.title = "Ajouter un site";
                this.modalSite.content = {};
                this.modalSite.edit = false;
                this.cleanForm('site');
            }

            this.modalSite.show = true;
        },

        cleanForm(type) {
            if (type === 'site') {
				this.form.id = ""
                this.form.name = ""
                this.form.nbr_pistol = 0
                this.form.default_create = 0
                this.form.nbr_tank = 0
                this.form.is_station = false
                this.form.is_active = true
			}
        },

        formRest(data) {
            this.form.id = data.id
            this.form.name = data.name
            this.form.nbr_pistol = data.nbr_pistol
            this.form.nbr_tank = data.nbr_tank
            this.form.is_station = data.is_station 
            this.form.default_create = data.default_create 
            this.form.is_active = data.is_active === 1 ? true: false
        },

        details(row) {
            this.selection.selected = []
            this.selection.select_all = false
            this.modalSitePan.content = row;
            this.modalSitePan.show = true
        },

        checkColorSub(date) {
        let bg = ["badge-primary", "badge-warning", "badge-danger", "badge-info"];
        if (date) {
            if (date.split(" ")[2] && date.split(" ")[2].toLowerCase() === "jours") {
            if (date.split(" ")[1] < 30 && date.split(" ")[1] > 15) {
                return bg[1];
            } else if (date.split(" ")[1] < 15 && date.split(" ")[1] > 0) {
                return bg[2];
            }
            } else {
            return bg[0];
            }
        } else {
            return bg[3];
        }
        },

        selectAll(site){
            this.selection.selected = []
            this.selection.select_all = !this.selection.select_all
            
            if (this.selection.select_all) {
                for (const i in this.data.rows) {
                    if (this.data.rows[i].id === site) {
                        for (const j in this.data.rows[i].users) {
                            if (Object.hasOwnProperty.call(this.data.rows[i].users, j)) {
                                const element = this.data.rows[i].users[j];
                                this.selection.selected.push(element.id)
                            }
                        }
                    }          
                }
            }
        },

        async loadSites () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('site/findAll')
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.sites.length === 0) {
                    this.empty = true
                }else{
                    this.empty = false
                }
                this.data.rows = response.data.sites
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async save() {
            this.$store.commit('site/SET_CLEAN')
            this.modalSite.btn = !this.modalSite.btn
            this.form.code = (this.$store.state.auth.company.id+' '+this.form.name.toLowerCase()).replace(' ', '_')
            await this.$store.dispatch('site/save', this.form)
			.then((response) => {
				this.modalSite.show = false
                this.modalSite.btn = !this.modalSite.btn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('site')
                this.loadSites()
			})
			.catch((error) => {
                this.modalSite.btn = !this.modalSite.btn
                if (error.response.data.errors) {
                    this.$store.commit('site/SET_NAME', error.response.data.errors.designation) 
                }
                if (error.response.data.errors) {
                    this.$store.commit('site/SET_PISTOL', error.response.data.errors.nbr_pistol) 
                }
                if (error.response.data.errors) {
                    this.$store.commit('site/SET_TANK', error.response.data.errors.nbr_tank) 
                }

                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {
            this.$store.commit('site/SET_CLEAN')
            this.modalSite.btn = !this.modalSite.btn

            this.form.code = (this.$store.state.auth.company.id+' '+this.form.name.toLowerCase()).replace(' ', '_')
			await this.$store.dispatch('site/update', this.form)
			.then((response) => {
				this.modalSite.show = false
                this.modalSite.btn = !this.modalSite.btn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('site')
                this.loadSites()
			})
			.catch((error) => {
                this.modalSite.btn = !this.modalSite.btn
                if (error.response.data.errors) {
                    this.$store.commit('site/SET_NAME', error.response.data.errors.designation) 
                }
                if (error.response.data.errors) {
                    this.$store.commit('site/SET_PISTOL', error.response.data.errors.nbr_pistol) 
                }
                if (error.response.data.errors) {
                    this.$store.commit('site/SET_TANK', error.response.data.errors.nbr_tank) 
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deleteSite(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('site/delete',id)
                .then((response) => {
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadSites()
                })
                .catch(error =>{
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                    isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },

        nameFormatter(){
            this.form.name = this.form.name.toUpperCase()
        }
    },

    created() {
        this.loadSites()    
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>

<style lang="css">
.custom-control {
    min-height: 0rem; 
}

.custom-control label{
}
</style> 