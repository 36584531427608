<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h3 class=" " style="font-weight:400" >Sites</h3>
            <mdb-btn
                    class="float-right"
                    tag="a"
                    color="primary"
                    icon="plus"
                    size="sm" 
                    small
                    v-if="$gate.hasPermission(['create::site'])"
                    @click="addOrEdit(null)"
                >
                </mdb-btn>
        </div>
        <div class="card card-search-os mb-4 px-0 py-0" style="border-radius:10px">
            <div class="card-body px-0 py-0">

                <div class="tec-search-v-icon">
                    <input   
                        style="background:transparent" v-model="dataPagination.searchQuery" 
                        type="search" ref="searchbar" name="search" 
                        placeholder="recherche ..." id="tec-search-v" class="tec-search-v">
                    <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="card card-list-os" v-if="empty" v-loading="loading" >
            <div class="card-body text-center">
                <div  class="d-flex justify-content-center w-100 p-5">
                    <img  class="w-50" :src="require('../../../../assets/svg/empty_streec.svg')"   alt="" srcset="">
                </div>
                    <h6>Aucune donnée</h6>
            </div>
        </div>
        <div class="" v-if="!empty" v-loading="loading" >
            <div class="card card-list-os mb-2" style="border-radius:10px!important;box-shadow:0px 0px 4px 1px rgb(0,0,0,0.15) !important"  v-for="row in queriedData" :key="row.id">
                <div class="card-body py-3 pl-2 d-flex align-items-center justify-content-between" >
                    <div style="max-width:90%">
                        <div class="mobile-card-header" style="font-size:0.8rem" v-if="false">
                            <span class="badger pb-2  d-block" style="">
                                <span v-if="row.is_subscribed === 1" class="badge " :class="checkColorSub($moment(row.expire_at).fromNow())" >Expire   {{$moment(row.expire_at).fromNow()}}</span>
                                <span v-if="row.is_subscribed === 0" class="badge  badge-info" >aucun abonnement</span>
                            </span>
                        </div>
                        <h5 class="card-title pb-2 mobile-card-title"  style="font-size:0.9rem;font-weight:700;font-family:'Nunito','Roboto',sans-serif"> 
                            <span v-if="row.is_active === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span>
                            <span v-if="row.is_active === 0" class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                            <span style="text-transform:uppercase;" class="pl-2">{{row.name}}</span>  
                        </h5>
                        <div class="mobile-card-describe mt-n3" style="font-weight:500;text-transform:uppercase; color:rgba(62, 69, 81, .7);">
                             
                        </div> 
                    </div>
                    <div class="action">
                        <button class="btn-mob" @click="showAction(row)" >
                            <i class="fas fa-ellipsis-v"></i>
                        </button>
                    </div>    
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <tec-pagination
                    v-model="dataPagination.pagination.currentPage"
                    :per-page="parseFloat(dataPagination.pagination.perPage)"
                    :total="dataPagination.pagination.total"
                ></tec-pagination>
            </div>
        </div>
        <mdb-modal  color="primary" centered size="sm" position="bottom" direction="bottom" :show="modalAction.show" @close="hideAction()">
            <mdb-modal-body class="px-0 py-0">
                <mdb-list-group flush>
                    <mdb-list-group-item  class="bg-primary white-text font-weight-bold" > 
                        <div>MENU</div> 
                        <button class="btn-mob" @click="hideAction()"> 
                            <i class="fas fa-times"></i> 
                        </button>
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg"
                        @click.native="showDetails(modalAction.content)"
                        icon="edit"  style="font-weight:400;" v-if="$gate.hasPermission(['view::site'])">
                        <div class="pl-3 menu-mob-tec">Voir details</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg"
                        @click.native="addOrEdit(modalAction.content)"
                        icon="edit"  style="font-weight:400;"          v-if="$gate.hasPermission(['edit::site'])">
                        <div class="pl-3 menu-mob-tec">Modifier le site</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg text-danger"
                        @click.native="deleteSite(modalAction.content.id)"   
                        icon="trash" style="font-weight:400;" >
                        <div class="pl-3 menu-mob-tec"         v-if="$gate.hasPermission(['delete::site'])">Supprimer le site</div>
                    </mdb-list-group-item>
                </mdb-list-group>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/Pagination'
import {
    mdbBtn,
    mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
} from 'mdbvue'
export default {
    components:{
        mdbBtn,
        mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
        TecPagination
    },
    props:{
        empty:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        },
        dataPagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            modalAction:{
                show:false,
                content:{}
            }
        }
    },
    methods:{
        showAction(data){
            this.modalAction.content  = data
            this.modalAction.show = true
        },

        showDetails(row){
            this.modalAction.show = false
            this.$emit("show_details",row);
        },

        hideAction(){
            this.modalAction.content  = {}
            this.modalAction.show = false
        },

        addOrEdit(data = null){
            this.modalAction.show = false
            this.$emit('add_or_edit',data)
        },
        
        selected(e){
            this.$emit('per_page_selected',e)
        },

        deleteSite(id)
        {
            this.modalAction.show = false
            this.$emit('delete_site', id)
		},
        checkColorSub(date){
            let bg =  [
                "bg-primary",
                "bg-warning",
                "bg-danger",
                "bg-info",
            ];
            if(date){
                if(date.split(' ')[2] && date.split(' ')[2].toLowerCase() === 'jours'){
                    if(date.split(' ')[1] < 30 && date.split(' ')[1] > 15){
                        return bg[1]
                    }else if(date.split(' ')[1] < 15 && date.split(' ')[1] > 0){
                        return bg[2]
                    }
                }else{
                    return bg[0]
                }
            }else{
                return bg[3]
            }
        }
    }
}
</script>